import request from '@/utils/request'

export function getMerchantGroups(params) {
  return request.get('/api/v1/backend/merchantGroups', { params })
}

export function addMerchantGroup(data) {
  return request.post('/api/v1/backend/merchantGroups', data)
}

export function updateMerchantGroup(id, data) {
  return request.put(`/api/v1/backend/merchantGroups/${id}`, data)
}

export function deleteMerchantGroup(id) {
  return request.delete(`/api/v1/backend/merchantGroups/${id}`)
}

export function getMerchants(params) {
  return request.get('/api/v1/backend/merchants', { params })
}

export function addMerchant(data) {
  return request.post('/api/v1/backend/merchants', data)
}

export function getMerchant(id) {
  return request.get(`/api/v1/backend/merchants/${id}`)
}

export function updateMerchant(id, data) {
  return request.put(`/api/v1/backend/merchants/${id}`, data)
}

export function deleteMerchant(id) {
  return request.delete(`/api/v1/backend/merchants/${id}`)
}
